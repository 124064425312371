import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { environment } from '@env/environment';
import { Navigate } from '@ngxs/router-plugin';
import { Store } from '@ngxs/store';
import { AuthManager } from '@shared/services/auth-manager.service';
import { AccountState } from '@shared/states/account.state';
import { ActionsState } from '@shared/states/actions.state';
import { GoToSignIn, ResetUserInfo, SignOutWithRedirect } from '@shared/states/auth.actions';
import { AuthState } from '@shared/states/auth.state';
import { PrefsState } from '@shared/states/prefs.state';
import { LocalStorage, LocalStorageService } from 'ngx-webstorage';
import { Observable, of } from 'rxjs';
import { catchError, filter, first, map, switchMap } from 'rxjs/operators';

function decodeRedirectUrl(route: ActivatedRouteSnapshot): string {
  const url = (route.queryParams.redirect || '').replace(`https:${environment.webAddress}`, '');

  return url ? decodeURIComponent(url) : '/surveys';
}

@Injectable({
  providedIn: 'root',
})
export class AuthGuard {
  @LocalStorage('remember') privateReportEmail: string | undefined;
  @LocalStorage('report-team') privateReportTeamKey: string | undefined;

  constructor(
    private store: Store,
    private am: AuthManager,
    private ls: LocalStorageService,
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    if (state.url.startsWith('/login')) {
      return this.loginRedirect(route, state);
    } else if (state.url.startsWith('/logout')) {
      return this.logoutRedirect();
    } else {
      return this.store.select(AuthState.isAccountOk).pipe(
        filter(Boolean),
        switchMap(() => {
          if (state.url.startsWith('/signup')) {
            return of(true);
          } else if (state.url.startsWith('/invite')) {
            return this.inviteRedirect();
          } else if (state.url.startsWith('/verify')) {
            return this.verifyRedirect(route);
          } else if (state.url.startsWith('/payment')) {
            return this.paymentRedirect(route);
          } else if (state.url.startsWith('/private')) {
            return this.privateRedirect(route);
          } else {
            return of(false);
          }
        }),
        first(),
      );
    }
  }

  private redirect(actions: any) {
    return this.store.dispatch(actions).pipe(map(() => false));
  }

  private inviteRedirect() {
    return of(true);
  }

  private loginRedirect(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const isSSOUrl = state.url.includes('/login/');

    const isNewUser = this.store.selectSnapshot(AuthState.isNewUser);
    const isSSOLogin = this.store.selectSnapshot(AuthState.isSSOLogin);
    const isAnonymous = this.store.selectSnapshot(AuthState.isAnonymous);
    const isUserVerified = this.store.selectSnapshot(AuthState.isUserVerified);
    const isAuthenticated = this.store.selectSnapshot(AuthState.isAuthenticated);

    const isSignOut = this.store.selectSnapshot(ActionsState.completedAction(SignOutWithRedirect));

    // Only logged in and none SSO provider users are redirected here, SSO logins have their own flows

    // TODO: Unify the normal logins as well to have their own login flows and perform re-login on login url

    if (!isSSOUrl && !isSignOut && !isNewUser && !isSSOLogin && !isAnonymous && isUserVerified && isAuthenticated) {
      const redirectUrl = decodeRedirectUrl(route);

      return this.redirect(new Navigate([redirectUrl], route.queryParams));
    } else {
      return of(true);
    }
  }

  private logoutRedirect() {
    return this.redirect(new SignOutWithRedirect(true));
  }

  private verifyRedirect(route: ActivatedRouteSnapshot) {
    console.log('Verifying email link');

    const redirectUrl = decodeRedirectUrl(route);
    const verificationCode = route.params.verificationCode;

    const isMobile = this.store.selectSnapshot(PrefsState.isMobile);

    return this.am.verifyCustomLogin(verificationCode).pipe(
      catchError(() => of(null)),
      switchMap((userKey) => (userKey ? this.am.userSignupData(userKey) : of(true))),
      map((ok) => {
        if (ok) {
          const params = { ...route.queryParams };

          delete params.redirect;

          const [, query] = redirectUrl.split('?');

          if (query) {
            query.split('&').forEach((part) => {
              const [param, value] = part.split('=');
              params[param] = value;
            });
          }

          if (isMobile) {
            return this.redirect(new Navigate(['/']));
          } else {
            return this.redirect(new Navigate(['/signup'], params));
          }
        }
      }),
      map(() => true),
    );
  }

  private paymentRedirect(route: ActivatedRouteSnapshot) {
    const isMobile = this.store.selectSnapshot(PrefsState.isMobile);

    if (isMobile) {
      return this.redirect(new GoToSignIn());
    } else {
      console.log('Verifying SCA key');

      const scaKey = route.params.linkKey;

      return this.am.verifyScaKey(scaKey).pipe(
        catchError(() => this.redirect(new Navigate(['/surveys'], { sca: true }))),
        map((ok) => {
          if (ok) {
            return this.redirect(new Navigate(['/sca/payment']));
          } else {
            return this.redirect(new GoToSignIn());
          }
        }),
        map(() => true),
      );
    }
  }

  private privateRedirect(route: ActivatedRouteSnapshot) {
    const linkKey = route.params.linkKey;
    const email = this.store.selectSnapshot(AccountState.identity)?.email || this.privateReportEmail;

    return this.am.verifyPrivateLink(linkKey, email).pipe(
      catchError((response) => {
        if (response?.error) {
          this.ls.clear('remember');
        }

        return of(null);
      }),
      map((response) => {
        if (response) {
          const { surveyKey, reportKey, teamKey } = response;

          this.privateReportTeamKey = teamKey;

          setTimeout(() => {
            this.redirect(
              new Navigate(['/report', surveyKey, reportKey], route.queryParams, {
                skipLocationChange: true,
              }),
            );
          }, 50);
        } else {
          return this.store.dispatch(new ResetUserInfo()).pipe(map(() => true));
        }
      }),
      map(() => true),
    );
  }
}
